<template>
  <v-card class="py-5">
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="12" class="primary--text text-h4 text-center">แจ้งเตือน</v-col>
        <v-col cols="12" class="primary--text text-h4 text-center">
          <v-icon color="primary" size="80">mdi-bell-ring</v-icon>
        </v-col>
        <v-col cols="8">
          <v-row>

            <v-col cols="3">ชื่อเรื่อง</v-col>
            <v-col cols="9" class="primary--text">โทรหาลูกค้า</v-col>

            <v-col cols="3">รายละเอียด</v-col>
            <v-col cols="9">-</v-col>

            <v-col cols="3">ผู้รับผิดชอบ</v-col>
            <v-col cols="9">-</v-col>

            <v-col cols="3">วันที่สร้าง</v-col>
            <v-col cols="9">-</v-col>
        
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5 px-10">
      <v-btn outlined class="px-5" color="grey" @click="close()">ปิด</v-btn>
      <v-spacer/>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="px-10" color="primary" v-bind="attrs" v-on="on" @click="save()">แจ้งเตือนภายหลัง</v-btn>
          </template>
          <v-list dense>
            <v-list-item>
              <v-list-item-title>อีก 30 นาที</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>อีก 1 ชั่วโมง</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>พรุ่งนี้</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>


    </v-card-actions>
  </v-card>
</template>

<script>
import { required, decimal } from "vuelidate/lib/validators";

export default {
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  
  methods: {
    close() {
      this.$emit("onClose");
    },
    save() {
    },
  },
};
</script>

